import React from "react";
// import Button from "@material-ui/core/Button";
// import red from "@material-ui/core/colors/red";

import "./App.css";
// import Env from "./firebase/index";

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          <div className="jp wf-hannari">おみくじ</div>
          <div className="en tangerine">omikuji.app</div>
        </h1>
      </header>
      <section className="Main">
        <p className="mainNumber">二十三番</p>
        <div className="box">
          <div className="jp wf-hannari">
            こころにいつも、
            <br />
            すきまかぜ。
          </div>
          <div className="jpRubi">Kokoro ni itsumo, sukima kaze.</div>
          <div className="en tangerine">Feeling always empty.</div>
        </div>
      </section>
      <button className="BtnReplay">
        <div className="en">TRY AGAIN</div>
      </button>
    </div>
  );
};

export default App;
